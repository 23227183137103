<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="container">
      <div class="con-1">
        <div class="mod2">
          <div class="item item1">
            <div class="s-pub-tk">
              <span class="bt">公共交通概况</span>
            </div>
          </div>
          <div class="item item2">
            <div class="txt">
              <p>
                <span class="tit">高速总长</span>
                <em>{{ this.publicTransportProfile.expressway}}</em>
                <span class="tit">km</span>
              </p>
            </div>
          </div>
          <div class="item item3">
            <div class="txt">
              <p>
                <span class="tit">高铁站总数</span>
                <em>{{ this.publicTransportProfile.highSpeedRailStation}}</em>
                <span class="tit">各</span>
              </p>
            </div>
          </div>
          <div class="item item4">
            <div class="txt">
              <p>
                <span class="tit">机场总数</span>
                <em>{{ this.publicTransportProfile.airportTotal}}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
          <div class="item item5">
            <div class="txt">
              <p>
                <span class="tit">交通接驳站数量</span>
                <em>{{ this.publicTransportProfile.trafficDockingDtation}}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
          <div class="item item6">
            <div class="txt">
              <p>
                <span class="tit">公交站数量</span>
                <em>{{ this.publicTransportProfile.busStop}}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
          <div class="item item7">
            <div class="txt">
              <p>
                <span class="tit">汽车站数量</span>
                <em>{{ this.publicTransportProfile.motorStation}}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
		  <div class="box">
		<img src="../assets/images/wt25.png" class="fll" alt="" />
      </div>  
        </div>
		
			
	 
		</div>
      
	
    
	</div>
  </div>
</template>

<script>
import Head from "@/components/head_sm.vue";
 import axios from 'axios'
export default {
  data() {
    return {
      headTitle: "",
        publicTransportProfile:{},
        loaded:false
    };
  },
  components: {
    Head,
  },
  mounted(){
     this.loaded = true;
     this.getData();
   },
    methods:{

   getData(){
      axios.get('/10zhjt.json').then(res => {
        // 处理响应
        // console.log("数据:"+res.data);
        let dataObject = res.data // 这里假设获取到的数据是一个 Object 类型的数据
        // console.log(dataObject.data);
        //
        let jsonString = JSON.stringify(dataObject) // 将 Object 类型的数据转换为字符串类型的 JSON 数据
        // console.log(jsonString) // 输出字符串类型的 JSON 数据
        let jsonObject = JSON.parse(jsonString)

        this.publicTransportProfile = jsonObject;
        //
        // this.schoolTotal = jsonObject.schoolTotal;
        //标题
        this.headTitle = jsonObject.bigTitle;

      }).catch(error => {
        console.log(error.response.status)
        console.log(error.response.data)
        console.log(error.response.headers)
      })
    },
        //  async getpublicTransportProfile(){
        //   const res = await axios.get('/zhjt.json')
        //   let dataObject = res.data;
        //   let jsonString = JSON.stringify(dataObject);
        //   let jsonObject = JSON.parse(jsonString);
        //   this.publicTransportProfile = jsonObject.publicTransportProfile;
        //   console.log(this.publicTransportProfile.expressway);
        //   console.log(this.publicTransportProfile.airportTotal);
        //   this.init1();
        //  async getSchoolData(){
      // this.$http({
      //   method:"POST",
      //   url:"http://scdata.shouxinfund.com/ls1236-MS005-zhjy.json",
      //   // data:{
      //   //   "current":this.currentPage,
      //   //   "size":this.pageSize
      //   // }
      // }).then(resp=>{
      //   console.log(resp.data);
      //   this.tableData = resp.data.records;
      //   this.total = resp.data.total;
      // })
      // axios.get('/zhjy.json').then(res => {
      //   // 处理响应
      //   // console.log("数据:"+res.data);
      //   let dataObject = res.data // 这里假设获取到的数据是一个 Object 类型的数据
      //   // console.log(dataObject.data);
      //   //
      //   let jsonString = JSON.stringify(dataObject) // 将 Object 类型的数据转换为字符串类型的 JSON 数据
      //   // console.log(jsonString) // 输出字符串类型的 JSON 数据
      //   let jsonObject = JSON.parse(jsonString)
      //   //
      //   this.schoolTotal = jsonObject.schoolTotal;
      //   //标题
      //   this.headTitle = this.webData.bigTitle;
      //
      // }).catch(error => {
      //   console.log(error.response.status)
      //   console.log(error.response.data)
      //   console.log(error.response.headers)
      // })
      // const res = await axios.get('/zhjy.json')
      // let dataObject = res.data;
      // let jsonString = JSON.stringify(dataObject);
      // let jsonObject = JSON.parse(jsonString);
      // this.schoolTotal = jsonObject.schoolTotal;
      // console.log(this.schoolTotal.student);
      // console.log(this.schoolTotal.teachers);
      // this.init1();
    
   }
    
   
};
</script>

<style scoped>
.page {
  background: url(../assets/images/map4.png) no-repeat center;
  background-size: 100% 100%;
}

.mod2 {
  height: 50%;
  width: 50%;
}
.mod3 {
  height: 30%;
  margin-bottom: 10px;
}

.mod2 .box{position: absolute;left: 28%;top: 12%;width: 1671px;
height: 1522.35px;opacity: 1;}
.mod2 .item {
  width: 350px;
  padding: 1px;
  border-radius: 10px;
  background: rgba(0, 48, 104, 0.55);
  opacity: 1;
  box-sizing: border-box;
  border: 5px solid;
  border-image: linear-gradient(157deg, #58a1ff 30%, #01407d 74%) 5;

  backdrop-filter: blur(20px);
  position: absolute;
}
.mod2 .item .txt {
  font-size: 52px;
  padding: 10px;
  font-weight: normal;
  letter-spacing: 10.4px;
  color: #45f5ff;
}
.mod2 .item .txt p {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.mod2 .item .tit {
  font-family: Source Han Sans CN;
  font-size: 45px;
  font-weight: 500;
  letter-spacing: 0px;

  color: #ffffff;
}

.mod2 .item .txt * {
  font-family: "YouSheBiaoTiHei";
}

.mod2 .item1 {
  left: 2%;
  bottom: 33%;
  width: 1016px;
  height: 69.9px;
  position: absolute;
  opacity: 1;

  background: rgba(0, 48, 104, 0.55);

  backdrop-filter: blur(20px);
}
.mod2 .item2 {
  left: 2%;
  bottom: 25%;
  width: 503px;
  height: 120px;
}
.mod2 .item3 {
  left: 16%;
  bottom: 25%;
  width: 503px;
  height: 120px;
}
.mod2 .item4 {
  left: 2%;
  bottom: 15%;
  width: 503px;
  height: 120px;
}
.mod2 .item5 {
  left: 16%;
  bottom: 15%;
  width: 503px;
  height: 120px;
}
.mod2 .item6 {
  left: 2%;
  bottom: 5%;
  width: 503px;
  height: 120px;
}
.mod2 .item7 {
  left: 16%;
  bottom: 5%;
  width: 503px;
  height: 120px;
}


.s-pub-tk {
  background: url(../assets/images/wt26.png) no-repeat left center #d1d3d600;
  background-size: auto 100%;
  padding-left: 100px;
  padding-right: 1px;
  align-items: center;
  height: 70px;
  bottom: 20px;
}
.s-pub-tk .bt {
  font-family: Source Han Sans CN;
  font-size: 46px;
  font-weight: normal;

  letter-spacing: 9.2px;

  color: #ffffff;
}

.con-1{
  width: 100%;
  height: 100%;
}


</style>
